<template>
  <component :is="roleData === undefined ? 'div' : 'b-card'">
    <!-- Alert: No item found -->
    <b-alert variant="danger" :show="roleData === undefined">
      <h4 class="alert-heading">Erreur de récupération des données du rôle</h4>
      <div class="alert-body">
        Pas de rôle trouvé sur cet identifiant. Vérifiez
        <b-link class="alert-link" :to="{ name: 'apps-roles-list' }">
          la liste des rôles
        </b-link>
        pour d'autres rôles.
      </div>
    </b-alert>

    <b-tabs v-if="roleData" pills>
      <!-- Tab: Général -->
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Général</span>
        </template>
        <handle-role-tab-general
          class="mt-2 pt-75"
          :role-data.sync="roleData"
          @saveData="onSubmit(roleData)"
        />
      </b-tab>

      <!-- Tab: Information -->
      <b-tab v-if="roleData.id">
        <template #title>
          <feather-icon icon="InfoIcon" size="16" class="mr-0 mr-sm-50" />
          <span class="d-none d-sm-inline">Modules</span>
        </template>
        <handle-role-tab-abilities
          class="mt-2 pt-75"
          :role-data.sync="roleData"
          :modules-data="modulesData"
          @toggleAbility="toggleAbility"
          @saveData="onSubmit(roleData)"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
/* eslint-disable */
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import { ref, onMounted, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import HandleRoleTabGeneral from "./HandleRoleTabGeneral.vue";
import HandleRoleTabAbilities from "./HandleRoleTabAbilities.vue";
import useRolesList from "../roles-list/useRolesList";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "HandleRole",
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    HandleRoleTabGeneral,
    HandleRoleTabAbilities,
  },
  setup() {
    // Use toast
    const toast = useToast();
    const { fetchModules, modulesData, onSubmit } = useRolesList();

    const blankRoleData = {
      name: "",
      is_active: true,
    };

    const roleData = ref(JSON.parse(JSON.stringify(blankRoleData)));

    const fetchRole = () => {
      store
        .dispatch("userGroup/fetchRole", { id: router.currentRoute.params.id })
        .then((role) => {
          roleData.value = role;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            roleData.value = undefined;
          }
        });
    };

    const toggleAbility = (e, data) => {
      const params = {
        id: roleData.value.id,
        module: data.id,
        is_attached: e,
      };
      store
        .dispatch("userGroup/toggleRoleModules", params)
        .then((role) => {
          roleData.value = role;
          toast({
            component: ToastificationContent,
            props: {
              title: "La mise à jour des modules a été effectuée avec succès.",
              icon: "AlertTriangleIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          toast({
            component: ToastificationContent,
            props: {
              title: "Une erreur est survenue lors de la mise à jour",
              icon: "AlertTriangleIcon",
              variant: "error",
            },
          });
        });
    };

    onMounted(() => {
      if (router.currentRoute.params.id) {
        fetchRole();
      }
      fetchModules();
    });

    return {
      roleData,
      modulesData,
      toggleAbility,
      onSubmit,
    };
  },
};
</script>

<style></style>
