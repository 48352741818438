<template>
  <div>
    <!-- Role Info: Input Fields -->
    <b-form>
      <b-row>

        <!-- Field: name -->
        <b-col
          cols="12"
          md="4"
        >
          <b-form-group
            label="Intitulé du Profil"
            label-for="name"
          >
            <b-form-input
              id="name"
              v-model="roleData.name"
              @input="$emit('update:name', $event)"
              :disabled="$route.name == 'apps-roles-edit' && !roleData.created_by"
            />
          </b-form-group>
        </b-col>
        
        <!-- Field: Status -->
        <b-col
          cols="12"
          md="4"
          v-if="roleData.id"
        >
          <b-form-group
            label="Statut"
            label-for="role-status"
          >
          <b-form-checkbox
            class="custom-control-success text-capitalize py-auto"
            name="check-button"
            v-model="roleData.is_active"
            :checked="roleData.is_active ? true : false"
            @change="$emit('update:is_active', $event)"
            switch
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right text-capitalize">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>

    <!-- Action Buttons -->
    <b-button
      variant="primary"
      class="mb-1 mb-sm-0 mr-0 mr-sm-1"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="$emit('saveData')"
    >
      Sauvegarder
    </b-button>
    <b-button
      variant="outline-secondary"
      type="reset"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      @click="$router.go(-1)"
    >
      Annuler
    </b-button>
  </div>
</template>

<script>
import {
  BButton, BRow, BCol, BFormGroup, BFormInput, BForm, BFormCheckbox,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'

export default {
  name: "HandleRoleTabGeneral",
  components: {
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
  },
  props: {
    roleData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const statusOptions = [
      { label: 'Active', value: true },
      { label: 'Inactive', value: false },
    ]

    // ? Demo Purpose => Update image on click of update
    const refInputEl = ref(null)
    const previewEl = ref(null)

    return {
      statusOptions,

      //  ? Demo - Update Image on click of update button
      refInputEl,
      previewEl,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
