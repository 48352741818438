<template>
  <div>
    <!-- ACCESSIBILITIES TABLE -->
    <b-card no-body class="border mt-1">
      <b-card-header class="p-1">
        <b-card-title class="font-medium-2">
          <feather-icon icon="LockIcon" size="18" />
          <span class="align-middle ml-50">Activation de Modules</span>
        </b-card-title>
      </b-card-header>
      <b-table
        striped
        responsive
        class="mb-0"
        :items="modulesData"
        :fields="tableColumns"
      >
        <template #cell(name)="data">
          {{ data.value }}
        </template>
        <template #cell()="data">
          <b-form-checkbox
            class="custom-control-success text-capitalize py-auto"
            name="check-button"
            :checked="isChecked(data.item) ? true : false"
            @change="$emit('toggleAbility', $event, data.item)"
            switch
          >
            <!-- @change="$emit('update:is_active', $event)" -->
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right text-capitalize">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
} from "bootstrap-vue";
import { ref } from "@vue/composition-api";

export default {
  name: "HandleRoleTabAbilities",
  components: {
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
  },
  props: {
    roleData: {
      type: Object,
      required: true,
    },
    modulesData: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    // Table Handlers
    const tableColumns = [
      {
        key: "name",
        label: "Libellé",
        sortable: true,
      },
      { key: "id", label: "Statut", sortable: true },
    ];

    const currRoleModules = ref([]);

    const mapRole = () => {
      const modules = props.roleData.modules;
      for (const module in modules) {
        currRoleModules.value.push(module);
      }
    };

    mapRole();

    const isChecked = (data) => {
      return currRoleModules.value.some((m) => m === data.name);
    };

    return {
      tableColumns,
      currRoleModules,
      isChecked,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
